html, body {
  background-color: #ffffff; 
  color: #000000; 
  margin: 0;
  padding: 0;
  font-family: "Calibri";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* * {
  background-color: #ffffff; 
  color: #000000;
} */

#root {
  white-space: pre-line;
}

html {
  overflow: hidden;
}

i,
img,
.icons {
  cursor: pointer;
}

/*=============== SCROLL BAR ===============*/
/* Hide scrollbar for all elements */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  display: none;
}

/* * {
  scrollbar-width: thin;
  scrollbar-color: grey;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  background: transparent;
}

* {
  scrollbar-color: transparent transparent;
} */

.coming-soon {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background: transparent 60% / cover no-repeat;
  background-image: url("./assets/img/coming-bg.png");
  background-position: left 100% bottom;
  background-repeat: no-repeat;
}

.coming-heading {
  color: #000;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  padding-top: 100px;
}

.coming-title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* margin-top: 25px; */
}

/* .css-2x394b {
  margin: 0px !important;
} */

.coming-description {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 800px;
  line-height: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .coming-description {
    width: 100%;
  }
}

.coming-buttons,
.reCaptcha-container,
.coming-text-field,
.privacy-policy-container,
.coming-social {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.coming-text-field {
  padding: 0 180px;
}

.coming-form-container {
  margin: auto;
}

.coming-fields {
  border-radius: 30px;
  width: 700px;
  border: 2px solid transparent;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-sqs87j-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover {
  font-family: "Calibri" !important;
  border-radius: 30px !important;
  border: none !important;
  background: #F4F4F4 !important;
  padding: 0 12px !important;
}

.css-1x5jdmq,
.css-1v4ccyo {
  font-family: "Calibri" !important;
  border-radius: 30px !important;
  border: none !important;
  background: #F4F4F4 !important;
  border: none !important;
}

.css-l1x25t {
  margin: 0px !important;
}

.privacy-policy {
  font-weight: 700;
  font-size: 14px;
  margin-top: -10px;
}

.footer-logo {
  width: 170px;
  margin-top: 60px;
}

.css-uhb5lp {
  border-radius: 30px !important;
  max-width: 900px !important;
  margin: 20px !important
}

.content {
  margin-top: -80px;
  height: 100vh;
}

.footer-content {
  margin-top: 80px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.coming-header-container {
  display: flex;
  justify-content: end;
}

.dropdown-menu li {
  transition: none;
}

.dropdown-toggle {
  background-color: white;
  border-radius: 20px;
  font-size: 12px;
  transition: none;
}

.dropdown {
  margin-top: 20px;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active,
.dropdown:hover,
.dropdown:focus,
.dropdown:active {
  background-color: white;
  border: none;
  transition: none;
  border-radius: 20px;
}

.dropdown-menu {
  border-radius: 20px;
  border: none;
}

.dropdown-item-span {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.header-logo {
  display: none;
}

.coming-buttons button {
  padding: 9px 115px;
}

.privacy-green {
  color: #66B855; 
  font-weight: 'bold';
}

@media (max-width: 990px) {
  .content {
    transform: scale(0.91);
  }

  .dropdown {
    margin-top: 25px;
    padding: 0px;
    z-index: 10;
  }

  .coming-heading {
    font-size: 45px;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1x5jdmq {
    font-size: 18px !important;
  }

  .coming-title {
    font-size: 20px;
  }

  .coming-description {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .footer-logo {
    display: none;
  }

  .header-logo {
    width: 120px;
    margin-top: 28px;
  }

  .footer-content {
    text-align: center;
    margin: auto;
    color: #828282;
    font-size: 12px;
  }

  .coming-header-container {
    display: flex;
    justify-content: space-between;
  }

  .header-logo {
    display: block;
  }

  .coming-text-field {
    padding: 0 10px !important;
  }

  .coming-buttons button {
    padding: 9px 120px;
  }

  .coming-fields {
    width: 650px;
  }
}

@media (max-width: 650px) {
  .coming-fields {
    width: 500px;
  }
}

@media (max-width: 470px) {
  .coming-fields {
    width: 450px;
  }

  .coming-heading {
    font-size: 32px;
  }

  .privacy-modal-title {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .coming-fields {
    width: 400px;
  }
}

@media (max-width: 350px) {
  .coming-fields {
    width: 350px;
  }
}

.privacy-modal-title {
  font-size: 25px;
  font-weight: 700;
}

.privacy-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-1x5jdmq {
  border-radius: 30px !important;
  max-width: 900px !important;
}

.css-1x5jdmq {
  padding: 16.5px 30px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0px !important;
}

.confirm-privacy-text {
  font-size: 18px;
  font-weight: 500;
}

.privacy-modal-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.privacy-btn {
  color: white !important;
  width: 140px !important;
  border-radius: 30px !important;
  border: none;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

@media (max-width: 355px) {
  .coming-buttons button {
    padding: 9px 80px !important;
  }
}

.css-1xsto0d {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.css-3d4410 {
  padding: 4px !important;
}

@media (max-width: 660px) {

  body,
  html {
    overflow: auto;
  }

  .coming-soon,
  .content {
    height: 100% !important;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  margin: 20px !important;
}

.custom-input {
  /* change default input is hidden */
  -webkit-appearance: none; 
  appearance: none;   
}

input[type="checkbox"].custom-input {
  border-radius: 3px;
  border: 2px solid #66B855;
  background-color: white;
  padding: 2px;
  margin-top: 10px;
  margin-right: 4px;
}

input[type="radio"].custom-input {
  border-radius: 50%;
}

.custom-input::before {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 14px 14px #333;
}

/* Styling to checkmark */
input[type="checkbox"].custom-input::before {
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
}

/* CHECKBOX 2 - Black BG & White check mark */
#custom-checkBox2:checked {
  background-color: #333;
}

#custom-checkBox2::before {
  box-shadow: inset 14px 14px #fff;
}

/* show only checkbox checked */
input[type="checkbox"].custom-input:checked::before{
  transform: scale(1);
}

/* Style to custom redio button */
input[type="radio"].custom-input::before {
  border-radius: 50%; 
  transform: scale(0);
}

/* Show radio button by checked */
input[type="radio"].custom-input:checked::before {
  transform: scale(.7);
}

/* RADIO BUTTON 2 - Black BG & White point */
#custom-radio2:checked {
  background-color: #333;
}

#custom-radio2::before {
  box-shadow: inset 14px 14px #fff !important;
}

